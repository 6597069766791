import React from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';
import FabAIChatWindow from './window/fabAIChatLanding';
import useAppContainer from './window/hooks/useAppContainer';

const unleashConfig = {
  url: process.env.UNLEASH_PROXY_URL ?? '',
  clientKey: process.env.UNLEASH_CLIENT_KEY ?? '',
  appName: 'copilot-admin',
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem('accountId') ?? '',
  },
};

const App = () => {
  return (
    <FlagProvider config={unleashConfig}>
      <AppContainer />
    </FlagProvider>
  );
};

const AppContainer = () => {
  const { isLoggedIn, showNewLandingPage } = useAppContainer();
  return (
    <>
      {isLoggedIn && showNewLandingPage && <FabAIChatWindow />}
    </>
  )
};

export default App;
