import React, { useEffect, useMemo, useRef, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { FabAIController } from '@copilot/mfa-communication';
import JsonRenderer from './json-renderer';
import {
  CHAT_STREAMING_PAYLOAD,
  CHAT_MESSAGE_TYPE,
  SUPPORTED_EVENT_TYPES,
} from './constants';
import { updateMessages } from './util';
import { MarkdownRenderer } from './MarkdownRenderer';

const sanitizeMarkdown = (text: string): string => {
  return text.replace(/\[NEWLINE\]+/g, `\n`);
};

const isValidStreamData = (streamData: string) => {
  const lines = streamData.split('\n\n');
  let isValid = true;
  lines.forEach(line => {
    if (line.trim()) {
      try {
        const jsonString = line.replace(/^data:\s*/, '');
        JSON.parse(jsonString);
      } catch (e) {
        isValid = false;
      }
    }
  });
  return isValid;
};

export const useChatStreaming = ({ content, setLoading }) => {
  const [messages, setMessages] = useState([]);

  const renderMessages = useMemo(() => {
    const renderedMessages = [];
    let currentText = '';

    messages.forEach((msg, index) => {
      if (msg.eventType === SUPPORTED_EVENT_TYPES.TEXT) {
        currentText += msg.data;
      }

      if (
        msg.eventType === SUPPORTED_EVENT_TYPES.JSON ||
        index === messages.length - 1
      ) {
        if (currentText) {
          renderedMessages.push(
            <MarkdownRenderer>{sanitizeMarkdown(currentText)}</MarkdownRenderer>
          );
          currentText = '';
        }

        if (msg.eventType === SUPPORTED_EVENT_TYPES.JSON) {
          renderedMessages.push(
            <JsonRenderer
              key={msg.id}
              content={msg}
              className='fade-in'
              data-testid={`json-response-${index}`}
            />
          );
        }
      }
    });
    return renderedMessages;
  }, [messages]);

  useEffect(() => {
    setMessages(content);
  }, [content]);

  useEffect(() => {
    if (messages.length > 0) {
      setLoading(false);
    }
  }, [messages]);

  return { messages, renderMessages };
};

export const useChatContainer = ({ setShowDefaultView, selectedChipQuery }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const chatContainerRef = useRef(null);
  const label = useMemo(
    () =>
      ['firstName', 'lastName']
        .map(key => sessionStorage.getItem(key) || '')
        .join(' '),
    []
  );

  useEffect(() => {
    if (messages.length !== 0) {
      setShowDefaultView(false);
    }
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    chatContainer.scrollIntoView({
      top: chatContainer.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  useEffect(() => {
    if (selectedChipQuery?.length > 0) {
      setInput(selectedChipQuery);
      handleInputSubmit(selectedChipQuery);
      setInput('');
    }
  }, [selectedChipQuery]);

  const fetchStream = async (promptText: string) => {
    setLoading(true);
    setInProgress(true);
    try {
      const messageId = uniqueId();
      const stream = await FabAIController.getChatStream({
        prompt: promptText,
        ...CHAT_STREAMING_PAYLOAD,
      });

      const reader = stream.getReader();

      let events = [];

      let streamData = '';

      while (true) {
        if (loading) {
          setLoading(false);
        }
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        streamData += chunk;
        if (!isValidStreamData(streamData)) {
          continue;
        }

        const lines = streamData.split('\n\n');

        lines.forEach(line => {
          if (line.trim()) {
            try {
              const jsonString = line.replace(/^data:\s*/, '');
              const event = JSON.parse(jsonString);
              events.push(event);
            } catch (e) {
              console.error('Error parsing event data:', e);
            }
          }
        });

        streamData = '';

        updateMessagesState(events, messageId);
      }

      handleStreamCompletion();
    } catch (err) {
      setLoading(false);
      setInProgress(false);
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.ERROR, content: err.message },
      ]);
    }
  };

  const updateMessagesState = (events, messageId) => {
    // Sort and set messages in state
    events = events.map((ev, index) => ({
      ...ev,
      showFabAiIcon: index === 0,
    }));

    events.sort((a, b) => a.id - b.id);
    setMessages(prev => updateMessages(prev, messageId, events));
  };

  const handleStreamCompletion = () => {
    setLoading(false);
    setInProgress(false);
  };

  const handleSubmit = async () => {
    handleInputSubmit(input);
    setInput('');
  };

  const handleInputSubmit = async (inputValue: string) => {
    if (inputValue.length > 0 && !inProgress && !loading) {
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.USER, content: inputValue },
      ]);
      await fetchStream(inputValue);
    }
  };

  return {
    messages,
    label,
    loading,
    setLoading,
    chatContainerRef,
    input,
    handleSubmit,
    inProgress,
    setInput,
  };
};
