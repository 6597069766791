import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FabAIObservables } from '@copilot/mfa-communication';

const useFabAIChatHomepage = () => {
  const [showDefaultView, setShowDefaultView] = useState(true);
  const [selectedChipQuery, setSelectedChipQuery] = useState('');
  const location = useLocation();

  const handleFabAIExpansion = useCallback(() => {
    if (location.pathname === '/welcome') {
      FabAIObservables.fullyOpenFabAIChat();
    } else {
      FabAIObservables.shrinkFabAIChat();
    }
  }, [location.pathname]);

  useEffect(() => {
    handleFabAIExpansion();
  }, [location.pathname]);

  return {
    showDefaultView,
    selectedChipQuery,
    setShowDefaultView,
    setSelectedChipQuery
  }
}
export default useFabAIChatHomepage;