import React from 'react';
import styled from 'styled-components';
import { GenericError, theme } from 'ds4-beta';
import {
  ProductDescription,
  AnalyticsTable,
  BarChartView,
} from './event-views';
import { StyledErrorContainer } from './style';
import { SUPPORTED_EVENTS, CHAT_ERROR_MESSAGE } from './constants';

const StyledAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space[4]};
`;

export const JsonRenderer = ({ content, ...restProps }) => {
  const { event, data: rawData } = content;
  const data = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
  const { PRODUCT_DESCRIPTION, EXCEPTION, OMS_ANALYTICS, PIM_ANALYTICS } =
    SUPPORTED_EVENTS;

  switch (event) {
    case PRODUCT_DESCRIPTION:
      return <ProductDescription data={data} {...restProps} />;
    case OMS_ANALYTICS:
    case PIM_ANALYTICS:
      return (
        <StyledAnalyticsContainer>
          {data?.response?.[0]?.dataset?.visualization?.chart === false && (
            <AnalyticsTable event={event} data={data} {...restProps} />
          )}
          <BarChartView data={data} />
        </StyledAnalyticsContainer>
      );
    case EXCEPTION:
      return (
        <StyledErrorContainer {...restProps}>
          <GenericError error={CHAT_ERROR_MESSAGE} dataTestid='chat-error' />
        </StyledErrorContainer>
      );
    default:
      return null;
  }
};

export default JsonRenderer;
