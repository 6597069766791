import React from 'react';
import { Icon, Box, theme } from 'ds4-beta';
import { StyledChatStreamContainer, StyledIcon } from './style';
import { CHAT_MESSAGE_TYPE, SUPPORTED_EVENTS } from './constants';
import { useChatStreaming } from './hook';

const ChatStreaming = ({ setLoading, content, loading }) => {
  const { messages, renderMessages } = useChatStreaming({
    content,
    setLoading,
  });

  return (
    <StyledChatStreamContainer
      isLastAiMessage={
        messages[messages.length - 1]?.type === CHAT_MESSAGE_TYPE.AI
      }
    >
      <Box width={theme.space[5]}>
        {!loading && content[0]?.event !== SUPPORTED_EVENTS.EXCEPTION && (
          <StyledIcon>
            <Icon
              iconName='CommerceGPT'
              size={24}
              variant={'fill'}
              dataTestid='commerceGPT-icon'
            />
          </StyledIcon>
        )}
      </Box>
      <Box flex={{ flexGrow: 1, flexDirection: 'column' }}>
        {renderMessages}
      </Box>
    </StyledChatStreamContainer>
  );
};

export default ChatStreaming;
