import { theme } from 'ds4-beta';
import styled from 'styled-components';

export const StyledMarkdownContainer = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  > * {
    animation: fadeIn 0.3s ease-in forwards;
  }

  code {
    background: ${theme.color.grey[100]};
    color: ${theme.color.grey[600]};
    border: 1px solid ${theme.color.grey[100]};
    border-radius: 4px;
    padding: 2px;
    ${theme.typography.code};
  }

  pre {
    padding: ${theme.space[2]};
    border-radius: 8px;
    background-color: ${theme.color.grey[100]};
    ${theme.typography.code};
    code {
      color: inherit;
      padding: 0;
      background: none;
      border: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    margin-left: 0;
    padding-left: 1rem;
    border-left: 2px solid ${theme.color.blue[400]};
  }

  hr {
    border: none;
    border-top: 2px solid ${theme.color.grey[200]};
    margin: ${theme.space[6]} 0;
  }

  p {
    ${theme.typography.body1.regular};
    font-weight: normal;
    &:first-child {
      margin-top: 0;
    }
  }

  strong {
    all: unset;
    font-weight: bold;
  }

  em {
    all: unset;
    font-style: italic;
  }

  s {
    all: unset;
    text-decoration: line-through;
  }

  u {
    all: unset;
    text-decoration: underline;
  }

  h1 {
    ${theme.typography.h1};
  }
  h2 {
    ${theme.typography.h2};
  }
  h3 {
    ${theme.typography.h3};
  }
  h4 {
    ${theme.typography.h4};
  }
`;
