import React, { useEffect, useState } from 'react';
import { FabAIController } from '@copilot/mfa-communication';
import {
  InitialPromptsContainer,
  PredefinedChipContainer,
  StyledHeaderContainer,
  StyledIconContainer,
  StyledIconContainerRow,
  StyledIconLandingPage,
  StyledSubTitleContainer,
  StyledTitleContainer,
} from './landingPage.style';
import { AIChatPrompterButton, Icon } from 'ds4-beta';
import { ChipsIconsMapping } from './constants';

const LandingPagePrompts = ({ setSelectedChipQuery }) => {
  const [predefinedChips, setPredefinedChips] = useState([]);
  useEffect(() => {
    getPredefinedSuggestionChips();
  }, []);
  const getPredefinedSuggestionChips = async () => {
    const resp = await FabAIController.getPredefinedPrompts();
    setPredefinedChips(resp);
  };
  const handleChatPrompterClick = predefinedChip => {
    setSelectedChipQuery(predefinedChip?.title);
  };
  return (
    <StyledIconLandingPage>
      <StyledHeaderContainer>
        <StyledIconContainerRow>
          <StyledIconContainer>
            <Icon iconName='Fabric' size={24} />
          </StyledIconContainer>
        </StyledIconContainerRow>

        <StyledTitleContainer>
          Hello. Welcome to fabric!
        </StyledTitleContainer>
        <StyledSubTitleContainer>
          Get started with one of the prompts below or ask me something else.
        </StyledSubTitleContainer>
      </StyledHeaderContainer>
      <InitialPromptsContainer padding={false}>
        {predefinedChips?.map((predefinedChip, index) => {
          return (
            <PredefinedChipContainer sm={12} md={6} lg={6} key={`predefined-chip-${predefinedChip.title}`}>
              <AIChatPrompterButton
                dataTestId={`predefined-chip-${index}`}
                iconName={ChipsIconsMapping[predefinedChip.type]}
                title={predefinedChip.title}
                onClick={() => handleChatPrompterClick(predefinedChip)}
              />
            </PredefinedChipContainer>
          );
        })}
      </InitialPromptsContainer>
    </StyledIconLandingPage>
  );
};
export default LandingPagePrompts;
