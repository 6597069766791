import React from 'react';
import {
  ChatBubbleLoadingContainer,
  ChatLoaderText,
  ChatLoadingContainer,
} from './styles';

const ChatBubbleLoadingIcon: React.FC = () => {
  return (
    <ChatLoadingContainer data-testid='chat-bubble-loading-icon'>
      <ChatBubbleLoadingContainer>
        <svg
          width='24'
          height='25'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.2524 12.773C19.2524 16.7785 16.0054 20.0255 11.9999 20.0255C7.9945 20.0255 4.74744 16.7785 4.74744 12.773C4.74744 8.76756 7.9945 5.52051 11.9999 5.52051'
            stroke='url(#paint0_linear_784_11595)'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_784_11595'
              x1='6.75'
              y1='19.5244'
              x2='18'
              y2='6.02441'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#9C21FC' />
              <stop offset='0.391855' stopColor='#440FB0' />
            </linearGradient>
          </defs>
        </svg>
      </ChatBubbleLoadingContainer>
      <ChatLoaderText>{'Getting you that information......'}</ChatLoaderText>
    </ChatLoadingContainer>
  );
};

export default ChatBubbleLoadingIcon;
