import { theme, ChatSenderType } from 'ds4-beta';
import styled from 'styled-components';
import { ChatBubbleType } from './types';

export const StyledChatBubbleContainer = styled.div<{
  sender: ChatBubbleType['sender'];
}>`
  padding: ${({ sender }) =>
    sender === ChatSenderType.USER ? `${theme.space[6]} 0px` : '0px'};
`;

export const ChatBubbleLoadingContainer = styled.div`
  height: 26px;
  position: relative;
  display: inline-block;
  animation: rotateSpinner 800ms linear infinite;

  @keyframes rotateSpinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ChatLoaderText = styled.div`
  ${theme.typography.body1.medium};
  display: flex;
  align-items: center;
`;

export const ChatLoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.space[2]};
  margin-left: ${theme.space[2]};
`;
