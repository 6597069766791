import React from 'react';
import {
  ChatPageContainer,
  StyledLandingPageContainer,
} from './components/home-page/landingPage.style';
import LandingPagePrompts from './components/home-page/landingPagePrompts';
import ChatContainer from './components/chat-container/chat-container';
import useFabAIChatHomepage from './hooks/useFabAIChatHomepage';

const FabAIChatHomePage = () => {
  const {
    showDefaultView,
    setShowDefaultView,
    selectedChipQuery,
    setSelectedChipQuery
  } = useFabAIChatHomepage();
  return (
    <ChatPageContainer>
      {showDefaultView && (
        <StyledLandingPageContainer>
          <LandingPagePrompts setSelectedChipQuery={setSelectedChipQuery} />
        </StyledLandingPageContainer>
      )}
      <ChatContainer
        setShowDefaultView={setShowDefaultView}
        selectedChipQuery={selectedChipQuery}
      />
    </ChatPageContainer>
  );
};
export default FabAIChatHomePage;
